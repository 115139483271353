import { useState } from 'react';
import { Form, useActionData, useNavigation } from 'react-router-dom';

import classes from './AuthForm.module.css';

function AuthForm() {

  const data = useActionData();
  const navigation = useNavigation();
  const [isLogin, setIsLogin] = useState(true);

  const isSubmitting = navigation.state === 'submitting';

  function switchAuthHandler() {
    setIsLogin((isCurrentlyLogin) => !isCurrentlyLogin);
  }

  return (
    <>
    <div className='row justify-content-center'>
      <div className="col-md-6">
      <Form method="post" className={classes.form}>
        <h1>{isLogin ? 'Log in' : 'Create a new user'}</h1>
        {data && data.message && (
          <ul>
            <li className="alert alert-danger">{data.message}</li>
          </ul>
        )}
        <p>
          <label htmlFor="email">Email</label>
          <input id="email" type="email" name="email" required />
        </p>
        <p>
          <label htmlFor="image">Password</label>
          <input id="password" type="password" name="password" required />
        </p>
        <div className={classes.actions}>
          
          <button disabled={isSubmitting}>{isSubmitting ? 'validating..' : 'Login'}</button>
        </div>
      </Form></div>
      </div>
    </>
  );
}

export default AuthForm;

import React, { useRef, useState } from 'react';
import { useParams, json, defer, Await, useNavigate, useNavigation } from 'react-router-dom';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import PageContent from '../components/PageContent';
const apiUrl = process.env.REACT_APP_API_URL;

function EmailBuilder() {
  const emailEditorRef = useRef(null);
  const {id} = useParams();

  const navigate = useNavigate();
  const navigation = useNavigation();
  
  const [loading, setLoading] = useState(false);
  const [loadBuilder, setBuilder] = useState(true);


  const exportHtml = () => {

    setLoading(true);
    
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml( async (data) => {
    const { design, html } = data;

      const authData = {
        html: html,
        design: design,
        tid: id,
        _authtoken : '8aPLkBmYhxzcxz4aVEvZ8gpbGWFkmPdwxPaGq4IftFxyORKtz75pvwrWow12T8rz',
        request_type : 'save-email-template'
      }
  
      const saveResponse = await fetch(apiUrl+'?request_type=save-email-template',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(authData)
      });

      setLoading(false);

      if (!saveResponse.ok) {
        throw json(
          { message: 'Could not load.' },
          {
            status: 500,
          }
        );
      }
      const saveData = await saveResponse.json();
    });
  };

  // const onReady: EmailEditorProps['onReady'] = (unlayer) => {
  //   // editor is ready
  //   // you can load your template here;
  //   // the design json can be obtained by calling
  //   // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)

  //   // const templateJson = { DESIGN JSON GOES HERE };
  //   // unlayer.loadDesign(templateJson);
  // };
  //const myInputRef = useRef(null);

  const onReady = async () => {
    setBuilder(false);
    //Access the DOM node directly through the ref
    if (myInputRef.current) {
      myInputRef.current.focus();
    }
    console.log("ready");

    const authData = {
      _authtoken : '8aPLkBmYhxzcxz4aVEvZ8gpbGWFkmPdwxPaGq4IftFxyORKtz75pvwrWow12T8rz',
      request_type : 'load-email-template',
      tid: id
    }
  
    const response = await fetch(apiUrl+'?request_type=load-email-template',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(authData)
      }
    );
  
    if (!response.ok) {
      throw json(
        { message: 'Could not load.' },
        {
          status: 500,
        }
      );
    }
  
    const resData = await response.json();
    
    const unlayer = emailEditorRef.current?.editor;

    if(resData.design!=''){
      unlayer.loadDesign(JSON.parse(resData.design));
    }
  };

  const myInputRef = useRef(null);


  return (
    <>
      <PageContent title="Email Builder">
          {loadBuilder ? 'Loading Builder...' : <button className="btn btn-outline-primary" onClick={exportHtml} disabled={loading}>
          {loading ? 'Wait...' : 'Save Email Template'}</button>}
          <EmailEditor ref={emailEditorRef} onReady={onReady} />
      </PageContent>
    </>
  );
}

export default EmailBuilder;

import { NavLink } from 'react-router-dom';

import classes from './EventsNavigation.module.css';

function EventsNavigation() {
  return (
    <nav>
      <div className="border-end bg-white" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom bg-light"><b>Bevly</b></div>
            <div className="list-group list-group-flush">
              <NavLink
                to="/dashboard"
                className="list-group-item list-group-item-action list-group-item-light p-3">
                Dashboard
              </NavLink>
              <NavLink
                to="/email-template"
                className="list-group-item list-group-item-action list-group-item-light p-3">
                Email Builder
              </NavLink>
              <NavLink
                to="/logout"
                className="list-group-item list-group-item-action list-group-item-light p-3">
                Logout
              </NavLink>
            </div>
        </div>
      </nav>
  );
}

export default EventsNavigation;

// import { useLoaderData } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';

function EmailList({events}) {
  // const events = useLoaderData();

  return (
    <div>
      <NavLink to='new' className="btn btn-outline-primary btn-sm float-end mb-2"> Add New Email Template</NavLink>
      <table className="table table-stripped table-bordered">
          <thead>
            <tr>
              <th>Email Name/ID</th>
              <th>Date Created</th>
              <th>Last Modified</th>
              <th>Triggered Event</th>
              <th className="text-center">Manage</th>
            </tr>
          </thead>
          <tbody>
          {events.map((event) => (
              <tr key={event.id}>
                <td><NavLink to={`edit/${event.id}`}> {event.name}</NavLink></td>
                <td>{event.createdate}</td>
                <td>{event.editdate}</td>
                <td>{event.event}</td>
                <td className="text-center">
                <NavLink to={`${event.id}`} className="btn btn-primary btn-sm"> Manage</NavLink>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
      
    </div>
  );
}

export default EmailList;

import EmailForm from '../components/EmailForm';
import PageContent from '../components/PageContent';
function DashboardPage() {
  return <PageContent title="Dashboard">
    <p></p>
  </PageContent>
}

export default DashboardPage;


import classes from './PageContent.module.css';
import EventsNavigation from '../components/EventsNavigation';
function PageContent({ title, children }) {
  return (
    <>
    <div className="d-flex">
      <EventsNavigation />
      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
          <div className="container-fluid h-bar">
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Hello, Data Admin!</a>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="#!">Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
          </div>
        </nav>
        <div className="container-fluid">
          <h3 className='mt-4 mb-4'>{title}</h3>
          {children}
        </div>
      </div>
    </div>
    </>
  );
}

export default PageContent;

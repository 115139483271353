import EmailForm from '../components/EmailForm';
import PageContent from '../components/PageContent';
function NewEmailPage() {
  return <PageContent title="New Email Template">
    <EmailForm method="post" />;
  </PageContent>
}

export default NewEmailPage;


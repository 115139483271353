import { useRouteLoaderData } from 'react-router-dom';

import EmailForm from '../components/EmailForm';
import PageContent from '../components/PageContent';

function EditEmailPage() {
  const data = useRouteLoaderData('event-detail');

  return <PageContent title="Edit Email Template">
    <EmailForm method="patch" event={data.event} />
  </PageContent>;
}

export default EditEmailPage;

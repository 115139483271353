import { Suspense } from 'react';
import { NavLink, useLoaderData, json, defer, Await } from 'react-router-dom';
import PageContent from '../components/PageContent';
import EmailList from '../components/EmailList';

const apiUrl = process.env.REACT_APP_API_URL;

function EmailTemplateList() {

  const { events } = useLoaderData();

  return (
    <>
      <PageContent title="Email Templates">

        <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
          <Await resolve={events}>
            {(loadedEvents) => <EmailList events={loadedEvents} />}
          </Await>
        </Suspense>
        
      </PageContent>
    </>
  );
}

export default EmailTemplateList;

export async function action({ request }) {
  const data = await request.formData();
  const email = data.get('email');

  // send to backend newsletter server ...
  console.log(email);
  return { message: 'Signup successful!' };
}

async function loadTemplateList() {

  const authData = {
    _authtoken : '8aPLkBmYhxzcxz4aVEvZ8gpbGWFkmPdwxPaGq4IftFxyORKtz75pvwrWow12T8rz',
    request_type : 'list-email-template'
  }

  const response = await fetch(apiUrl+'?request_type=list-email-template',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(authData)
    }
  );

  if (!response.ok) {
    throw json(
      { message: 'Could not fetch events.' },
      {
        status: 500,
      }
    );
  }

  const resData = await response.json();
  return resData.list;
  
}

export function loader() {
  return defer({
    events: loadTemplateList(),
  });
}
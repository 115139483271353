import AuthForm from '../components/AuthForm';
import { json, redirect } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

function AuthenticationPage() {
  return <AuthForm />;
}
export default AuthenticationPage;

export async function action({request}) {
  const data = await request.formData();
  const authData = {
    email: data.get('email'),
    password: data.get('password'),
    _authtoken : '8aPLkBmYhxzcxz4aVEvZ8gpbGWFkmPdwxPaGq4IftFxyORKtz75pvwrWow12T8rz',
    request_type : 'email-template-login'
  }

  console.log('at>>',JSON.stringify(authData));

  const response = await fetch(apiUrl+'?request_type=email-template-login',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(authData)
    }
  );

  if(response.statusCode === 422 || response.statusCode === 401){
    return response;
  }

  if(!response.ok){
    throw json({message: 'Could not authenticate user!'}, {status: 500})
  }

  const resData = await response.json();
  
  if(resData.status === 0){
    return resData;
  }

  else if(resData.status === 1){
    localStorage.setItem('token', resData.token);
    return redirect('/email-template');
  }
  console.log('login response>>',(resData.token));
  return redirect('/');
}